import WalletAdjustmentFilter from './WalletAdjustmentFilter';
import WalletAdjustmentTable from './WalletAdjustmentTable';
import HTTP from 'helpers/ApiClient';
import { useEffect, useMemo, useState } from 'react';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import { useSingleSelection, useToggle } from 'utils/hooks';
import { useDispatch, useSelector } from 'react-redux';
import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
} from 'components/Modal/ErrorModal';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import styles from './WalletAdjustment.module.css';
import { strToNumber } from 'utils/common';
import { showAccessDeniedModal } from 'redux/modules/access';
import { ReducerStateType } from 'redux/modules/reducers';
import { IScope } from 'utils/verifyPermission';
import { capitalize, get as _get } from 'lodash';
import { UserInfoUtil } from 'utils/session';
import {
	ErrorModalConfig,
	SuccessModalConfig,
} from '../WalletReplenishment/WalletReplenishment';
import SuccessModal, {
	SuccessModalActions,
	SuccessModalBody,
	SuccessText,
} from 'components/Modal/SuccessModal';
import { calibrateDate } from 'utils/date';

const resolveValue = (value, defaultValue = undefined) => {
	return value || defaultValue;
};

const APPROVER_SCOPE_NAME = 'bip.wallet.approval.adjustment';

async function getApprovalAmounts(roleId, permissionId) {
	const result = await HTTP.get(
		`/v1/permissions/approval/${roleId}/${permissionId}`
	);
	return result.data;
}

type GetAdjustmentsArgs = {
	page?: number;
	pageSize?: number;
	limit?: number;
	sortBy?: string;
	sort?: string;
	dateCreatedFrom?: string | null;
	dateCreatedTo?: string | null;
	partnerName?: string;
	transactionType?: string;
	status?: string;
	requestNumber?: string;
};
async function getAdjustments(values: GetAdjustmentsArgs = {}) {
	const {
		page = 1,
		pageSize = 25,
		sortBy,
		sort,
		dateCreatedFrom,
		dateCreatedTo,
		partnerName,
		transactionType,
		status,
		requestNumber,
	} = values;

	const params = {
		sortBy,
		sort,
		page,
		limit: pageSize,
		dateCreatedFrom,
		dateCreatedTo,
		partnerName: resolveValue(partnerName),
		transactionType: resolveValue(transactionType),
		status: resolveValue(status),
		requestNumber: resolveValue(requestNumber),
	};
	const result = await HTTP.get('/v2/wallet/adjustment-list', { params });
	return result.data;
}

const initialValues = {
	dateFrom: undefined,
	dateTo: undefined,
	channelName: '',
	transactionType: '',
	status: '',
	requestNumber: '',
};
const WalletAdjustment: React.FC = () => {
	const [data, setData] = useState([]);
	const [page, setPage] = useState(1);
	const [count, setCount] = useState(0);
	const [pageSize, setPageSize] = useState(25);
	const [sortBy, setSortBy] = useState('date_created');
	const [sort, setSort] = useState<'asc' | 'desc'>('desc');
	const [approvalAmount, setApprovalAmount] = useState([]);
	const [filterParams, setFilterParams] = useState<GetAdjustmentsArgs>({});
	const [isFiltering, setIsFiltering] = useState<boolean>(false);
	const [errorSecondaryMessage, setErrorSecondaryMessage] = useState<
		string | undefined
	>('Please try again.');

	const uamRole = useSelector<ReducerStateType>(
		(state) => state.userInfo?.uamRole
	);
	const scopes = useSelector<ReducerStateType>(
		(state) => state.userInfo?.scopes || []
	) as unknown as IScope[];

	const approverScope = useMemo(
		() => scopes.find(({ scope }) => scope === APPROVER_SCOPE_NAME),
		[scopes]
	);

	const dispatch = useDispatch();

	const {
		value: isLoading,
		valueOn: showLoading,
		valueOff: hideLoading,
	} = useToggle();

	const {
		value: isErrorModalShown,
		valueOn: showErrorModal,
		valueOff: hideErrorModal,
	} = useToggle();

	const {
		value: isSuccessModalOpen,
		valueOn: showSuccessModal,
		valueOff: hideSuccessModal,
	} = useToggle();

	const [lastParams, setLastParams] = useState({});

	useEffect(() => {
		if (!isErrorModalShown) {
			resetErrorModalConfig();
		}
	}, [isErrorModalShown]);

	useEffect(() => {
		if (!isSuccessModalOpen) {
			resetSuccessModalConfig();
		}
	}, [isSuccessModalOpen]);

	const handleRetryGetWalletAdjustments = () => {
		hideErrorModal();
		if (lastParams) {
			fetchAdjustments(lastParams, false, true, 5);
		}
	};

	const {
		value: errorModalConfig,
		setValue: setErrorModalConfig,
		resetValue: resetErrorModalConfig,
	} = useSingleSelection<ErrorModalConfig>({
		initialValue: {
			Body: () => (
				<div className={styles.errorBody}>
					<div>Oops!</div>
					<div>A problem occurred while</div>
					<div>loading the wallet adjustment list.</div>
				</div>
			),
			onRetry: handleRetryGetWalletAdjustments,
		},
	});
	const {
		value: successModalConfig,
		setValue: setSuccessModalConfig,
		resetValue: resetSuccessModalConfig,
	} = useSingleSelection<SuccessModalConfig>({
		initialValue: {
			header: 'Success',
			Body: () => (
				<div className={styles.subtext}>You've approved the adjustment</div>
			),
			onClose: hideSuccessModal,
		},
	});

	const initialFetch = async () => {
		try {
			showLoading();
			// Uncomment if getapprovalamount is needed (this function is not yet needed at the time of writing this) - this throws a 403 error, preventing to fetch the list of adjustments.
			if (approverScope) {
				const { id: permissionId } = approverScope || {};
				const { id: roleId }: any = uamRole || {};
				if (permissionId !== undefined && roleId !== undefined) {
					const response = await getApprovalAmounts(roleId, permissionId);
					setApprovalAmount(
						_get(response, ['data'], []).map(({ min_amount, max_amount }) => ({
							minAmount: strToNumber(min_amount),
							maxAmount: strToNumber(max_amount),
						}))
					);
				}
			}
			await fetchAdjustments({}, false, false, 6);
		} catch (e: any) {
			console.log(e);
			if (e.response && e.response.status === 403) {
				dispatch(showAccessDeniedModal());
			} else {
				showErrorModal();
			}
		} finally {
			hideLoading();
		}
	};

	type FetchAdjustmentsArgs = Omit<
		GetAdjustmentsArgs,
		'dateCreatedFrom' | 'dateCreatedTo'
	> & {
		dateFrom?: GetAdjustmentsArgs['dateCreatedFrom'];
		dateTo?: GetAdjustmentsArgs['dateCreatedTo'];
	};

	const fetchAdjustments = async (
		values: FetchAdjustmentsArgs = {},
		retry = false,
		withLoader = true,
		tagNumber = 0
	) => {
		const doRequest = async (p) => {
			try {
				withLoader && showLoading();
				setLastParams(p);
				const result = await getAdjustments(p);
				const { meta } = result.data;
				setData(result.data.data);
				setPage(meta.page);
				setPageSize(meta.pageSize);
				setCount(meta.total);
			} catch (e) {
				if (withLoader) {
					showErrorModal();
					return;
				}
				throw e;
			} finally {
				withLoader && hideLoading();
			}
		};

		if (retry) {
			doRequest(values);
			return;
		}

		const {
			dateFrom: dateCreatedFrom,
			dateTo: dateCreatedTo,
			...rest
		} = values;

		const dates = calibrateDate(dateCreatedFrom, dateCreatedTo);

		const params = {
			sortBy,
			sort,
			page,
			limit: pageSize,
			...dates,
			...rest,
			...values,
		};
		await doRequest(params);
	};
	useEffect(() => {
		initialFetch();
	}, []);

	const handleFilterSubmit = async (values) => {
		setIsFiltering(true);
		const {
			partnerName = {},
			dateFrom,
			dateTo,
			transactionType,
			status,
			requestNumber,
		} = values;

		const params = {
			partnerName: partnerName?.label,
			dateFrom,
			dateTo,
			status,
			page: 1,
			pageSize,
			transactionType,
			requestNumber,
		};

		setPage(1);
		setFilterParams(params);
		await fetchAdjustments(
			{
				partnerName: partnerName?.label,
				dateFrom,
				dateTo,
				transactionType,
				status,
				requestNumber,
				page: 1,
			},
			false,
			true,
			1
		);
		setIsFiltering(false);
	};

	const handlePageChange = async (_page, _pageSize) => {
		if (isFiltering) return;
		const {
			page: _,
			pageSize: __,
			...rest
		} = filterParams as GetAdjustmentsArgs;

		setPage(_page);
		setPageSize(_pageSize);
		await fetchAdjustments(
			{ page: _page, pageSize: _pageSize, ...rest },
			false,
			true,
			2
		);
	};

	const handleSort = async (sortBy, sort) => {
		setSortBy(sortBy);
		setSort(sort);
		setPage(1);
		await fetchAdjustments(
			{ sortBy, sort, page: 1, ...filterParams },
			false,
			true,
			3
		);
	};

	const handleRetry = () => {
		hideErrorModal();
		if (lastParams) {
			fetchAdjustments(lastParams, true, true, 4);
		}
	};

	const hiddenColumns = approverScope ? [] : ['actions'];
	const shouldAllowApproval = (status: string, amount: number) => {
		if (!approverScope) return false;
		if (status !== 'PENDING') return false;

		const a = strToNumber(amount);
		return approvalAmount.some(
			({ minAmount, maxAmount }) => a >= minAmount && a <= maxAmount
		);
	};

	const approvalApiRequestBuilder =
		(action: 'approve' | 'decline') =>
		async (id: string): Promise<void> => {
			try {
				showLoading();
				const data = {
					updated_by: {
						username: UserInfoUtil.get().username,
					},
				};
				
				const url = action === 'approve' ? `/v1/wallet-adjustments/new/${id}/${action}` : `/v1/wallet-adjustments/${id}/${action}`;
				await HTTP.post(url, data);

				const actionSpiel = action === 'approve' ? 'approved' : 'declined';
				setSuccessModalConfig({
					header: `${capitalize(action)} Wallet Adjustment`,
					Body: () => (
						<div className={styles.subtext}>
							You've {actionSpiel} the adjustment
						</div>
					),
					onClose: () => {
						handleRetryGetWalletAdjustments();
						hideSuccessModal();
					},
				});
				showSuccessModal();
			} catch (e: any) {
				const isWillNegativeMotherBalance =
					e.response &&
					e.response.data &&
					e.response.data.message &&
					e.response.data.message.includes('below zero');

				const isMaximumBalance =
					e.response &&
					e.response.data &&
					e.response.data.message &&
					e.response.data.message.includes('The wallet');

				const isMaximumMotherBalance =
					e.response &&
					e.response.data &&
					e.response.data.message &&
					e.response.data.message.includes('The mother wallet');

				setErrorSecondaryMessage(
					(isWillNegativeMotherBalance && ' ') ||
						(isMaximumBalance &&
							'The wallet has reached the maximum balance amount.') ||
						(isMaximumMotherBalance &&
							'The mother wallet has reached the maximum balance amount.') ||
						'Please try again.'
				);

				setErrorModalConfig({
					Body: () =>
						isWillNegativeMotherBalance ? (
							<strong>
								Failed to approve adjustment due to insufficient wallet
								balances.
							</strong>
						) : (
							<strong>
								{isMaximumMotherBalance || isMaximumBalance
									? 'Cannot complete the transaction'
									: `Failed to ${action} the adjustment`}
							</strong>
						),
					onRetry: () => {
						hideErrorModal();
						if (
							isWillNegativeMotherBalance ||
							isMaximumMotherBalance ||
							isMaximumBalance
						)
							return;
						approvalApiRequestBuilder(action)(id);
					},
				});
				showErrorModal();
			} finally {
				hideLoading();
			}
		};
	const approveAdjustment = approvalApiRequestBuilder('approve');
	const declineAdjustment = approvalApiRequestBuilder('decline');

	const { Body: ErrorBody, onRetry: handleErrorRetry } =
		errorModalConfig as ErrorModalConfig;
	const { Body: SuccessBody, onClose: handleSuccessModalClose } =
		successModalConfig as SuccessModalConfig;

	return (
		<>
			<WalletAdjustmentFilter
				initialValues={initialValues}
				onSubmit={handleFilterSubmit}
				onHandleSuccess={handleRetry}
				handlePageChange={handlePageChange}
			/>
			<WalletAdjustmentTable
				page={page}
				data={data}
				pageSize={pageSize}
				count={count}
				onPageChange={handlePageChange}
				onSort={handleSort}
				sortBy={sortBy}
				sort={sort}
				hiddenColumns={hiddenColumns}
				shouldAllowApproval={shouldAllowApproval}
				onApprove={approveAdjustment}
				onDecline={declineAdjustment}
			/>
			<FullPageLoader
				open={isLoading}
				message="Please wait while wallet adjustment list is being loaded"
			/>
			<ErrorModal open={isErrorModalShown} onClose={hideErrorModal}>
				<ErrorModalBody>
					<ErrorBody />
					<div className={styles.errorFooter}>{errorSecondaryMessage}</div>
				</ErrorModalBody>
				<ErrorModalActions>
					<PrimaryButton
						fullWidth
						onClick={handleErrorRetry}
						className={styles.retryBtn}
					>
						{errorSecondaryMessage === 'Please try again.' ? 'Retry' : 'Close'}
					</PrimaryButton>
				</ErrorModalActions>
			</ErrorModal>
			<SuccessModal open={isSuccessModalOpen} onClose={hideSuccessModal}>
				<SuccessModalBody>
					<SuccessText>
						<div>Success!</div>
						<SuccessBody />
					</SuccessText>
				</SuccessModalBody>
				<SuccessModalActions>
					<PrimaryButton
						className={styles.successDoneBtn}
						onClick={handleSuccessModalClose}
					>
						Done
					</PrimaryButton>
				</SuccessModalActions>
			</SuccessModal>
		</>
	);
};

export default WalletAdjustment;

import WalletReplenishmentFilter from './WalletReplenishmentFilter';
import WalletReplenishmentTable from './WalletReplenishmentTable';
import HTTP from 'helpers/ApiClient';
import { useCallback, useEffect, useMemo, useState } from 'react';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import { useSingleSelection, useToggle } from 'utils/hooks';
import { useDispatch, useSelector } from 'react-redux';
import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
} from 'components/Modal/ErrorModal';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import styles from './WalletReplenishment.module.css';
import { resolveValue, strToNumber } from 'utils/common';
import WalletReplenishmentModal from 'components/WalletReplenishmentModal/WalletReplenishmentModal';
import { showAccessDeniedModal } from 'redux/modules/access';
import { BIP_WALLET_REPLENISHMENT_APPROVAL_PERMISSION } from 'utils/permissions';
import { IScope } from 'utils/verifyPermission';
import { ReducerStateType } from 'redux/modules/reducers';
import { UserInfoUtil } from 'utils/session';
import SuccessModal, {
	SuccessModalActions,
	SuccessModalBody,
	SuccessText,
} from 'components/Modal/SuccessModal';
import { capitalize } from 'lodash';
import { calibrateDate } from 'utils/date';

type GetReplenishmentsArgs = {
	page?: number;
	pageSize?: number;
	sortBy?: string;
	sort?: string;
	dateCreatedFrom?: string | null;
	dateCreatedTo?: string | null;
	partnerName?: string;
	serviceType?: string;
	status?: string;
	requestNumber?: string;
};

type FetchReplenishmentsArgs = Omit<
	GetReplenishmentsArgs,
	'dateCreatedFrom' | 'dateCreatedTo'
> & {
	dateFrom?: GetReplenishmentsArgs['dateCreatedFrom'];
	dateTo?: GetReplenishmentsArgs['dateCreatedTo'];
};

async function getReplenishments(values: GetReplenishmentsArgs = {}) {
	const {
		page = 1,
		pageSize = 25,
		sortBy,
		sort,
		dateCreatedFrom,
		dateCreatedTo,
		partnerName,
		status,
		requestNumber,
	} = values;
	const params = {
		sortBy,
		sort,
		page,
		limit: pageSize,
		dateCreatedFrom,
		dateCreatedTo,
		partnerName:
			resolveValue(partnerName) == '' ? undefined : resolveValue(partnerName),
		status: resolveValue(status) == '' ? undefined : resolveValue(status),
		requestNumber:
			resolveValue(requestNumber) == ''
				? undefined
				: resolveValue(requestNumber),
	};
	const result = await HTTP.get('/v2/wallet/replenishment-list', { params });
	return result.data;
}

const initialValues = {
	dateFrom: undefined,
	dateTo: undefined,
	partnerName: {},
	status: '',
};

const sanitizeAmount = (amount: string) => {
	return strToNumber(amount.replace(',', ''));
};

export type ErrorModalConfig = {
	Body: React.FC;
	onRetry: () => void | Promise<void>;
};

export type SuccessModalConfig = {
	header: string;
	Body: React.FC;
	onClose: () => void;
};

const WalletReplenishment: React.FC = () => {
	const [data, setData] = useState([]);
	const [page, setPage] = useState(1);
	const [count, setCount] = useState(0);
	const [pageSize, setPageSize] = useState(25);
	const [sortBy, setSortBy] = useState('date_created');
	const [sort, setSort] = useState<'asc' | 'desc'>('desc');
	const [approvalData, setApprovalData] = useState<Array<any>>([]);
	const [isSearch, setIsSearch] = useState(false);
	const [filterParams, setFilterParams] = useState<GetReplenishmentsArgs>({});
	const {
		value: isLoading,
		valueOn: showLoading,
		valueOff: hideLoading,
	} = useToggle();
	const {
		value: isSuccessModalOpen,
		valueOn: showSuccessModal,
		valueOff: hideSuccessModal,
	} = useToggle();

	const {
		value: isErrorModalShown,
		valueOn: showErrorModal,
		valueOff: hideErrorModal,
	} = useToggle();

	const dispatch = useDispatch();

	const handleRetryGetWalletReplenishments = () => {
		hideErrorModal();
		if (lastParams) {
			fetchReplenishments(lastParams);
		}
	};

	const {
		value: errorModalConfig,
		setValue: setErrorModalConfig,
		resetValue: resetErrorModalConfig,
	} = useSingleSelection<ErrorModalConfig>({
		initialValue: {
			Body: () => (
				<div className={styles.errorBody}>
					<div>Oops!</div>
					<div>A problem occurred while</div>
					<div>loading the wallet replenishment list.</div>
				</div>
			),
			onRetry: handleRetryGetWalletReplenishments,
		},
	});
	const {
		value: successModalConfig,
		setValue: setSuccessModalConfig,
		resetValue: resetSuccessModalConfig,
	} = useSingleSelection<SuccessModalConfig>({
		initialValue: {
			header: 'Success',
			Body: () => (
				<div className={styles.subtext}>You've approved the replenishment</div>
			),
			onClose: hideSuccessModal,
		},
	});

	useEffect(() => {
		if (!isErrorModalShown) {
			resetErrorModalConfig();
		}
	}, [isErrorModalShown]);

	useEffect(() => {
		if (!isSuccessModalOpen) {
			resetSuccessModalConfig();
		}
	}, [isSuccessModalOpen]);

	const scopes = useSelector<ReducerStateType>(
		(state) => state.userInfo?.scopes || []
	) as unknown as IScope[];

	const uamRole = useSelector<ReducerStateType>(
		(state) => state.userInfo?.uamRole || {}
	) as unknown as any;
	const roles = useSelector<ReducerStateType>(
		(state) => state.userInfo?.roles || []
	) as unknown as any[];

	const [lastParams, setLastParams] = useState({});

	const approvalPermission = useMemo<IScope | undefined>(
		() =>
			scopes.find(
				(obj) =>
					obj.scope === BIP_WALLET_REPLENISHMENT_APPROVAL_PERMISSION.scope
			),
		[scopes]
	);
	const hiddenColumns = approvalPermission ? [] : ['actions'];

	const shouldAllowApproval = useCallback(
		(status: string, amount: number) => {
			if (!approvalPermission) return false;
			if (status?.toUpperCase() !== 'PENDING') return false;

			const a = strToNumber(amount);
			return approvalData.some(
				({ max_amount: maxAmount, min_amount: minAmount }) => {
					const max = sanitizeAmount(maxAmount);
					const min = sanitizeAmount(minAmount);

					return a >= min && a <= max;
				}
			);
		},
		[approvalData, approvalPermission]
	);

	const approvalApiRequestBuilder =
		(action: 'approve' | 'decline') =>
		async (id: string, type: string): Promise<void> => {
			try {
				showLoading();
				const data = {
					current_user: {
						username: UserInfoUtil.get().username,
						// amount: 5
					},
				};

				let url;
				if (type == 'mother') {
					// approval for mother wallet (not yet needed)
					// url = `/v1/mother-wallet/replenishments/${id}/${action}`;
				} else {
					url = action === 'approve' ? `/v1/wallet-replenishments/new/${id}/${action}` : `/v1/wallet-replenishments/${id}/${action}`;
				}
				await HTTP.post(url, data);

				const actionSpiel = action === 'approve' ? 'approved' : 'declined';
				setSuccessModalConfig({
					header: `${capitalize(action)} Wallet Replenishment`,
					Body: () => (
						<div className={styles.subtext}>
							You've {actionSpiel} the replenishment
						</div>
					),
					onClose: () => {
						handleRetryGetWalletReplenishments();
						hideSuccessModal();
					},
				});
				showSuccessModal();
			} catch (error) {
				console.log('Error', error);
				setErrorModalConfig({
					Body: () => <strong>Failed to {action} the replenishment</strong>,
					onRetry: () => {
						hideErrorModal();
						approvalApiRequestBuilder(action)(id, type);
					},
				});
				showErrorModal();
			} finally {
				hideLoading();
			}
		};
	const approveReplenishment = approvalApiRequestBuilder('approve');
	const declineReplenishment = approvalApiRequestBuilder('decline');

	const fetchReplenishments = async (
		values: FetchReplenishmentsArgs = {},
		retry = false,
		withLoader = true
	) => {
		const doRequest = async (p) => {
			try {
				showLoading();
				if (approvalPermission) {
					const role = roles.find(
						(_r) => _r.platform.id === uamRole.platform?.id
					);
					const {
						data: { data = [] },
					} = await HTTP.get(
						`/v1/permissions/approval/${role.id}/${approvalPermission.id}`
					);
					setApprovalData(Array.isArray(data) ? data : []);
				}
				setLastParams(p);
				const result = await getReplenishments(p);
				const { meta } = result.data;
				setData(result.data.data);
				setPage(meta.page);
				setPageSize(meta.pageSize);
				setCount(meta.total);
			} catch (e: any) {
				if (e.response?.status === 403) {
					dispatch(showAccessDeniedModal());
				} else {
					showErrorModal();
				}
			} finally {
				setIsSearch(false);
				hideLoading();
			}
		};

		if (retry) {
			doRequest(values);
			return;
		}

		const {
			dateFrom: dateCreatedFrom,
			dateTo: dateCreatedTo,
			...rest
		} = values;

		const dates = calibrateDate(dateCreatedFrom, dateCreatedTo);

		const params = {
			sortBy,
			sort,
			page,
			limit: pageSize,
			...dates,
			...rest,
			...values,
		};
		await doRequest(params);
	};

	useEffect(() => {
		fetchReplenishments();
	}, []);

	const handleFilterSubmit = async (values, _isSearch) => {
		const {
			partnerName = {},
			dateFrom,
			dateTo,
			status,
			requestNumber,
		} = values;
		const params = {
			partnerName: partnerName.label,
			dateFrom,
			dateTo,
			status,
			page: 1,
			pageSize,
			requestNumber,
		};

		setPage(1);
		setFilterParams(params);
		await fetchReplenishments(params);
		setIsSearch(_isSearch);
	};

	const handlePageChange = async (_page, _pageSize) => {
		const {
			page: _,
			pageSize: __,
			...rest
		} = filterParams as GetReplenishmentsArgs;
		setPage(_page);
		setPageSize(_pageSize);
		await fetchReplenishments({ page: _page, pageSize: _pageSize, ...rest });
	};

	const handleSort = async (sortBy, sort) => {
		setSortBy(sortBy);
		setSort(sort);
		await fetchReplenishments({ sortBy, sort, ...filterParams });
	};

	const { Body: ErrorBody, onRetry: handleErrorRetry } =
		errorModalConfig as ErrorModalConfig;
	const { Body: SuccessBody, onClose: handleSuccessModalClose } =
		successModalConfig as SuccessModalConfig;

	return (
		<>
			<WalletReplenishmentModal
				successSubmit={handleRetryGetWalletReplenishments}
				reloadTable={() => handlePageChange(1, 25)}
			/>
			<WalletReplenishmentFilter
				initialValues={initialValues}
				onSubmit={handleFilterSubmit}
			/>
			<WalletReplenishmentTable
				page={page}
				data={data}
				pageSize={pageSize}
				count={count}
				onPageChange={handlePageChange}
				onSort={handleSort}
				sortBy={sortBy}
				sort={sort}
				shouldAllowApproval={shouldAllowApproval}
				onApprove={approveReplenishment}
				onDecline={declineReplenishment}
				hiddenColumns={hiddenColumns}
				isSearch={isSearch}
			/>
			<FullPageLoader
				open={isLoading}
				message="Please wait while wallet replenishment list is being loaded"
			/>
			<SuccessModal open={isSuccessModalOpen} onClose={hideSuccessModal}>
				<SuccessModalBody>
					<SuccessText>
						<div>Success!</div>
						<SuccessBody />
					</SuccessText>
				</SuccessModalBody>
				<SuccessModalActions>
					<PrimaryButton
						className={styles.successDoneBtn}
						onClick={handleSuccessModalClose}
					>
						Done
					</PrimaryButton>
				</SuccessModalActions>
			</SuccessModal>
			<ErrorModal open={isErrorModalShown} onClose={hideErrorModal}>
				<ErrorModalBody>
					<ErrorBody />
					<div className={styles.errorFooter}>Please try again</div>
				</ErrorModalBody>
				<ErrorModalActions>
					<PrimaryButton
						fullWidth
						onClick={handleErrorRetry}
						className={styles.retryBtn}
					>
						Retry
					</PrimaryButton>
				</ErrorModalActions>
			</ErrorModal>
		</>
	);
};

export default WalletReplenishment;
